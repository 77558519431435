import { Button, Grid, IconButton, List, ListItem, ListItemText, TextField } from "@mui/material";
import React, { useState } from "react";

const DomainListField = ({domains, addDomain, removeDomain}) => {

    const [newDomain, setNewDomain] = useState("");

    return (
        <>
            <div><b>Authorised Domains:</b></div>
            <div><i style={{color: "#888", fontSize: "0.8em"}}>Add domains that are authorised to call the API. For example: "yoursite.com". No "https://" prefix.</i></div>
            <Grid container spacing={2} mt={1}>
                <Grid item md={12}>
                    <List style={{border: "1px solid #ccc", borderRadius: "5px"}}>
                        {domains.map((domain, key) => (
                            <ListItem style={key===0?{}:{borderTop: "1px solid #ccc"}} key={key} secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => {
                                    removeDomain(key);
                                }}>
                                    <i className="fa fa-trash fa-xs" />
                                </IconButton>
                            }>
                                <ListItemText primary={domain} />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item md={10}>
                    <TextField label="Domain" variant="outlined" fullWidth onChange={e => setNewDomain(e.target.value)} value={newDomain} />
                </Grid>
                <Grid item md={2} alignItems={"stretch"} style={{display: "flex"}} justifyContent="right">
                    <Button variant="contained" color="primary" onClick={() => {
                        // domain cannot be empty
                        const regex = /^\s*$/;
                        if(!regex.test(newDomain)){
                            addDomain(newDomain);
                            setNewDomain("");
                        }
                    }}><i className="fa fa-plus" style={{marginRight: "10px"}} /> Add Domain</Button>
                </Grid>
            </Grid>
        </>
        
    )
}

export default DomainListField;