import { Container, Paper, Box, Card, CardHeader, CardContent, Alert, LinearProgress, Grid, Button, Table, TableCell, TableHead, TableRow, TableBody } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import Chart from "react-google-charts";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import Loader from "../../../../components/Loader";
import { Link } from "react-router-dom";

const Overview = () => {
    const title = 'Dashboards';
    const mountedRef = useRef(true);

    const { userData } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    const [loading, setLoading] = useState(true);
    const [loadingErrors, setLoadingErrors] = useState(true);

    const [error, setError] = useState("");

    const [usage, setUsage] = useState(0);
    const [requests, setRequests] = useState(0);
    const [dailyData, setDailyData] = useState([]);
    const [errorLog, setErrorLog] = useState([]);

    const [lastMonth, setLastMonth] = useState(false);

    function getDaysInMonth(lastMonth) {
        var date = new Date();
        var month = date.getUTCMonth();
        if(lastMonth){
            month = date.getUTCMonth()-1;
            date = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth()-1, 1));
        }else{
            month = date.getUTCMonth();
            date = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
        }
        var days = [];
        while (date.getUTCMonth() === month) {
          days.push(new Date(date));
          date.setUTCDate(date.getUTCDate() + 1);
        }
        return days;
    }
    
    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/",
                text: userData.currentAccount.name,
                active: false
            },      
            {
                to: null,
                text: title,
                active: true
            }
        ]);

    }, [userData, setBreadcrumb, title]);

    useEffect(() => {
        // get month usage
        setLoading(true);

        const getMonthUsage = CloudFunctions.httpsCallable('getMonthUsage');
        getMonthUsage({
            accountId: userData.currentAccount.id,
            lastMonth: lastMonth
        }).then(res => {
            if (!mountedRef.current) return null
            let days = [];
            const allDays = getDaysInMonth(lastMonth);
            for(let i=0; i<allDays.length; i++){
                days[allDays[i].toISOString().split('T')[0]] = 0;
            }
            let total = 0;
            for(let i=0; i<res.data.length; i++){
                const row = res.data[i];
                const date = row.Hour.value.split("T")[0];
                if(days[date]){
                    days[date] = days[date] + row.Requests
                }else{
                    days[date] = row.Requests;
                }
                total = total + row.Requests;
            }
            setRequests(total);
            setUsage(total/userData.currentAccount.cap*100);
            let data = [];
            data.push(["Date", "Requests", {role: "style"}]);
            for(let k in days){
                data.push([k, days[k], "color: #1976d2"]);
            }
            setDailyData(data);
            setLoading(false);
        }).catch(err => {
            if (!mountedRef.current) return null
            setError(err.message);
            setLoading(false);
        });
    }, [userData, lastMonth])

    useEffect(() => {
        return () => { 
            mountedRef.current = false
        }
    },[]);

    useEffect(() => {
        setLoadingErrors(true);
        const getErrorLog = CloudFunctions.httpsCallable('getErrorLog');
        getErrorLog({
            accountId: userData.currentAccount.id,
        }).then(res => {
            if (!mountedRef.current) return null
            setErrorLog(res.data);
            setLoadingErrors(false);

        }).catch(err => {
            if (!mountedRef.current) return null
            setLoadingErrors(false);
        });
    },[userData])

    return (
        <Container>
            <Paper>
                <Box mt={2}>
                    <Card>
                        <CardHeader title="Usage Dashboard" />
                        <CardContent>
                            {(error) && <Alert severity="error" onClose={() => setError(null)}>{error}</Alert> }
                            {(loading) && <Loader text="Loading data..." size={16} />}
                            {!loading && 
                                <>
                                    <Grid container>
                                        <Grid item xs>
                                            {userData.currentAccount.cap === 0?(
                                                <>
                                                    <span>Monthly Usage: {requests.toLocaleString()} requests. 
                                                        {!lastMonth && <i>(Update every 5 minutes)</i>}
                                                    </span>
                                                </>
                                            ):(
                                                <>
                                                    <p>Monthly Usage: {requests.toLocaleString()}/{userData.currentAccount.cap.toLocaleString()} requests, {usage.toFixed(2).toLocaleString()}% of budget. 
                                                    {!lastMonth && <i>(Update every 5 minutes)</i>}
                                                    </p>
                                                    {requests>0 && <LinearProgress variant="determinate" value={usage} sx={{borderRadius: '5px', height: '12px'}} />}
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            {lastMonth?(
                                                <Button onClick={() => setLastMonth(false)}>View Current Month</Button>
                                            ):(
                                                <Button onClick={() => setLastMonth(true)}>View Last Month</Button>
                                            )}                                           
                                        </Grid>
                                    </Grid>

                                    <Chart chartType="ColumnChart" width="100%" height="500px" data={dailyData} />
                                </>
                            }
                        </CardContent>
                    </Card>
                </Box>
            </Paper>
            <Paper>
                <Box mt={2}>
                    <Card>
                        <CardHeader title="Error Dashboard" />
                        <CardContent>
                            {(loadingErrors) && <Loader text="Loading data..." size={16} />}
                            {!loadingErrors && 
                                <>
                                    <p>It takes 5 to 10 minutes to update the error log. Refresh the dashboard in 10 minutes to check the latest updates.</p>
                                    {errorLog.length > 0?(
                                        <>
                                            <p>To resolve 403 error, add the domain to <Link to={'/account/'+userData.currentAccount.id+'/settings'}>your authorised domain list</Link></p>
                                            <p>To resolve 402 error, <Link to={'/account/'+userData.currentAccount.id+'/billing/plan'}>upgrade to the "Pay as You Go" plan</Link></p>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Hour
                                                        </TableCell>
                                                        <TableCell>
                                                            Error Type
                                                        </TableCell>
                                                        <TableCell>
                                                            Domain
                                                        </TableCell>
                                                        <TableCell>
                                                            Errors
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {errorLog.map((row, index) => 
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                {new Date(row.Hour.value).toLocaleDateString()}
                                                                &nbsp;
                                                                {new Date(row.Hour.value).toLocaleTimeString()}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.ResponseCode}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.Domain}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.Requests}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </>
                                    ):
                                    (
                                        <p>Congrats, No errors found in the last 24 hours</p>
                                    )}

                                </>
                            }
                        </CardContent>
                    </Card>
                </Box>
            </Paper>
        </Container>
    )
}

export default Overview;