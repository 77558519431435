import React, { useContext, useEffect, useState, useRef } from "react";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { Form, Input } from '../../../../components/Form';
import { Container, Paper, Box, Alert } from "@mui/material";
import DomainListField from "../../../../components/DomainListField";
import BudgetField from "../../../../components/BudgetField";

const Settings = () => {
    const title = 'Project Settings';
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const { userData, setUserData } = useContext(AuthContext);
    const mountedRef = useRef(true);

    const [accountName, setAccountName] = useState({
        hasError: false,
        error: null,
        value: userData.currentAccount.name
    });

    const [domains, setDomains] = useState(userData.currentAccount.domains);

    const bucketSize = 100000;
    const [capBucket, setCapBucket] = useState(userData.currentAccount.cap / bucketSize);

    const [errorMessage, setErrorMessage] = useState(null);
    const [success, setSuccess] = useState(false);

    const [inSubmit, setInSubmit] = useState(false);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/",
                text: userData.currentAccount.name,
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [setBreadcrumb, title, userData]);

    useEffect(() => {
        return () => { 
            mountedRef.current = false
        }
    },[]);


    return (
        <Container>
            <Paper>
                <Box p={2}>
                    {success && 
                        <Alert severity="success" onClose={() => setSuccess(false)}>Project settings have been updated successfully.</Alert>
                    }
                    {errorMessage !== null && 
                        <Alert severity="error" onClose={() => setErrorMessage(null)}>{errorMessage}</Alert>
                    }
                    <div className="card-body">
                        <Form handleSubmit={e =>{
                            e.preventDefault();
                            setInSubmit(true);
                            setErrorMessage(null);
                            setSuccess(false);
                            const updateAccount = CloudFunctions.httpsCallable('updateAccount');
                            updateAccount({
                                accountId: userData.currentAccount.id,
                                accountName: accountName.value,
                                domains: domains,
                                cap: capBucket*bucketSize
                            }).then(response => {
                                if (!mountedRef.current) return null 
                                // refresh current account data
                                let account = userData.currentAccount;
                                account.name = accountName.value;
                                account.domains = domains;
                                account.cap = capBucket*bucketSize;
                                setUserData(userData => ({
                                    ...userData,
                                    currentAccount: account
                                }));
                                setSuccess(true);
                                setInSubmit(false);
                            }).catch(err => {
                                if (!mountedRef.current) return null 
                                setErrorMessage(err.message);
                                setInSubmit(false);
                            })
                        }}
                        disabled={accountName.hasError || accountName.value===null || inSubmit}
                        inSubmit={inSubmit}
                        enableDefaultButtons={true}>
                            <Input label="Website Name" type="text" name="account-name" maxLen={100} required={true} changeHandler={setAccountName} fullWidth variant="outlined" defaultValue={accountName.value} />
                            
                            <DomainListField domains={domains} addDomain={newDoamin => {
                                setDomains(domains => [...domains, newDoamin])
                            }} removeDomain={index => {
                                const d = [...domains];
                                d.splice(index, 1);
                                setDomains(d);
                            }} />

                            {userData.currentAccount.price > 0 && 
                                <BudgetField capBucket={Math.floor(capBucket)} setCapBucket={setCapBucket}/>
                            }
                        </Form>
                    </div>
                </Box>
            </Paper>
        </Container>
    );
}

export default Settings;