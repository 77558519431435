import { Grid, TextField } from "@mui/material";
import React from "react";

const BudgetField = ({capBucket, setCapBucket}) => {

    return (
        <>
            <div style={{marginTop: "20px"}}><b>Budget:</b></div>
            <Grid container spacing={2} mt={1}>
                <Grid item md={2}>
                    <TextField type="number" variant="outlined" fullWidth onChange={e => setCapBucket(Math.floor(e.target.value))} value={capBucket} />
                </Grid>
                <Grid item md={10} alignItems={"stretch"} style={{display: "flex", flexDirection: 'column', justifyContent: 'center'}}>
                    x {(100000).toLocaleString()} = {(capBucket*100000).toLocaleString()} requests (${(capBucket*5).toLocaleString()}) per month
                </Grid>
            </Grid>
            <div style={{marginTop: "10px", color: "#888888"}}><small>Alerts will be sent via email when usage reach 50%, 75%, 90% and 100%. Set 0 if you don't want to set a budget and receive alerts.</small></div>
        </>
        
    )
}

export default BudgetField;