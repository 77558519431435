import { Container, Paper, Box, Card, CardHeader, CardContent, Typography, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { useContext, useEffect, useRef } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import config from "../../../../inc/config.json";
import { useLocation } from "react-router-dom";
import { pushToDataLayer } from "../../../../libs/datalayer";

  

const Installation = () => {
    const title = 'Dashboards';
    const mountedRef = useRef(true);
    const { hash } = useLocation();
    useEffect(() => {
        switch(hash){
            case "#new-free":
                pushToDataLayer({event: "subscribe", subscriptionPlan:"free", subscriptionType:"new"});
                break;
            case "#new-paid":
                pushToDataLayer({event: "subscribe", subscriptionPlan:"paid", subscriptionType:"new"});
                break;
            case "#change":
                pushToDataLayer({event: "subscribe", subscriptionPlan:"paid", subscriptionType:"change"});
                break;
            default:
                break;
        }
    },[hash]);

    const { userData } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    
    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/",
                text: userData.currentAccount.name,
                active: false
            },      
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [userData, setBreadcrumb, title]);

    useEffect(() => {
        return () => { 
            mountedRef.current = false
        }
    },[]);


    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Container>
            <Paper>
                <Box>
                    <Card>
                        <CardHeader title="How to use VisitorAPI" />
                        <CardContent>
                            <Box sx={{ width: '100%' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList value={value} onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label="Vanilla JavaScript" value="1" />
                                            <Tab label="Reactjs" value="2" />
                                            <Tab label="Google Tag Manager" value="3" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <Typography variant="h6" component="span">Step 1: Load VisitorAPI</Typography>
                                        <pre style={{backgroundColor: "#333333", color: "#cccccc", borderRadius: "10px", padding: "30px", fontSize: "0.8em", whiteSpace: "pre-wrap"}}>
                                            
                                                {
    `var VisitorAPI=function(t,e,a){var s=new XMLHttpRequest;s.onreadystatechange=function(){var t;s.readyState===XMLHttpRequest.DONE&&(200===(t=JSON.parse(s.responseText)).status?e(t.data):a(t.status,t.result))},s.open("GET","`+config.endpoint+`?pid="+t),s.send(null)};`
                                                }
                                            
                                        </pre>
                                        <Typography variant="h6" component="span">Step 2: Call VisitorAPI</Typography>
                                        <Typography>VisitorAPI function takes three parameters:</Typography>
                                        <ul>
                                            <li>Project ID: The ID to specify this project <b><i>{userData.currentAccount.id}</i></b></li>
                                            <li>Success handler function: A function to handle the data from a successful API call</li>
                                            <li>Error handler function: A function to handle the errors. Two parameters are passed to the handler function: error code and error message.</li>
                                        </ul>
                                        <Typography>Below is an example to print the data in the console when the API is called successfully, or print the error code and the error message when it fails.</Typography>
                                        <pre style={{backgroundColor: "#333333", color: "#cccccc", borderRadius: "10px", padding: "30px", fontSize: "0.8em", whiteSpace: "pre-wrap"}}>
                                                {
    `VisitorAPI(
        "`+userData.currentAccount.id+`",
        function(data){console.log(data)},
        function(errorCode, errorMessage){console.log(errorCode, errorMessage)}
    );`
                                                }
                                        </pre>
                                        <Typography>Please read <a href="https://www.visitorapi.com/help-articles/get-started"  rel="noreferrer" target="_blank">documentation</a> for more details.</Typography>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Typography variant="h6" component="span">Step 1: Install VisitorAPI</Typography>
                                        <pre style={{backgroundColor: "#333333", color: "#cccccc", borderRadius: "10px", padding: "30px", fontSize: "0.8em", whiteSpace: "pre-wrap"}}>
                                            
                                                {
    `npm install visitorapi`
                                                }
                                            
                                        </pre>
                                        <Typography variant="h6" component="span">Step 2: Call VisitorAPI</Typography>
                                        <p>VisitorAPI function takes three parameters:</p>
                                        <ul>
                                            <li>Project ID: The ID to specify this project <b><i>{userData.currentAccount.id}</i></b></li>
                                            <li>Success handler function: A function to handle the data from a successful API call</li>
                                            <li>Error handler function: A function to handle the errors. Two parameters are passed to the handler function: error code and error message.</li>
                                        </ul>
                                        <p>Below is an example to store the data in state variables when the API is called successfully.</p>
                                        <pre style={{backgroundColor: "#333333", color: "#cccccc", borderRadius: "10px", padding: "30px", fontSize: "0.8em", whiteSpace: "pre-wrap"}}>
                                                {
    `const VisitorAPI = require("visitorapi");

    const [visitorData, setVisitorData] = useState({}); // store the whole json

    const [ipAddress, setIpAddress] = useState("");

    const [countryCode, setCountryCode] = useState("");
    const [countryName, setCountryName] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [cityLatLong, setCityLatLong] = useState(""); // city center latitude and longitude 

    const [currencies, setCurrencies] = useState([]); // currencies data is an array
    const [languages, setLanguages] = useState([]); // languages data is an array

    const [browser, setBrowser] = useState("");
    const [browserVersion, setBrowserVersion] = useState("");

    const [deviceBrand, setDeviceBrand] = useState("");
    const [deviceModel, setDeviceModel] = useState("");
    const [deviceFamily, setDeviceFamily] = useState("");

    const [os, setOs] = useState("");
    const [osVersion, setOsVersion] = useState("");

    useEffect(() => {
    VisitorAPI(
        "`+userData.currentAccount.id+`",
        data => {
        setVisitorData(data);
        setIpAddress(data.ipAddress);
        setCountryCode(data.countryCode);
        setCountryName(data.countryName);
        setState(data.region);
        setCity(data.city);
        setCityLatLong(data.cityLatLong);
        setCurrencies(data.currencies);
        setLanguages(data.languages);
        setBrowser(data.browser);
        setBrowserVersion(data.browserVersion);
        setDeviceBrand(data.deviceBrand);
        setDeviceModel(data.deviceModel);
        setDeviceFamily(data.deviceFamily);
        setOs(data.os);
        setOsVersion(data.osVersion);
        }
    );
    },[]);`
                                                }
                                        </pre>
                                        <p>Please read <a href="https://www.visitorapi.com/help-articles/react"  rel="noreferrer" target="_blank">documentation</a> for more details.</p>   
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <Typography variant="h6" component="span">Step 1: Import VisitorAPI tag template</Typography>
                                        <p>In Google Tag Manager workspace, click on “Templates”, then click on “Search Gallery” and search for <i><b>"visitorapi"</b></i>. Import the VisitorAPI tag template into your Google Tag Manager workspace</p>
                                        <Typography variant="h6" component="span">Step 2: Create a VisitorAPI tag</Typography>
                                        <p>Create a new tag in the Google Tag Manager workspace, and select VisitorAPI under “Custom” as the tag type.</p>
                                        <p>Copy and paste <b><i>{userData.currentAccount.id}</i></b> to the Project ID field in the VisitorAPI tag configurations, and set the triggering condition as the pages you will need the location and device data for other tags.</p>
                                        <Typography variant="h6" component="span">Step 3: Create Google Tag Manager variables</Typography>
                                        <p>To use the data, create variables in Google Tag Manager to map the data in dataLayer. When creating the variables, choose Data Layer Variable, then map the new variable to one of the data layer variable created by the VisitorAPI tag.</p>
                                        <Typography variant="h6" component="span">Step 4: Create triggers for tags</Typography>
                                        <p>When you create tags that needs the location and device as triggering conditions in Google Tag Manager, set the trigger conditions based on event <b><i>“visitor-api-success”</i></b> so that the tag will be trigger when the VisitorAPI tag successfully fetch the data, and set the trigger based on the variables.</p>
                                        <p>Please read <a href="https://www.visitorapi.com/help-articles/google-tag-manager"  rel="noreferrer" target="_blank">documentation</a> for more details.</p> 
                                    </TabPanel>
                                </TabContext>
                            </Box>    
                        </CardContent>
                    </Card>
                </Box>
            </Paper>
        </Container>
    )
}

export default Installation;