import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { AppBar as MuiAppBar, Drawer as MuiDrawer, Toolbar, CssBaseline, Divider, Box, Paper, Button } from '@mui/material';
import Logo from '../Logo';
import {BreadcrumbContext, Breadcrumb} from '../Breadcrumb';
import HelpIcon from '@mui/icons-material/HelpOutline';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Layout = ({drawerMenu, toolbarChildren, toolBarMenu, children}) => {
  const open = true;

  const [breadcrumb, setBreadcrumb] = useState([]);

  return (
    <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
            <Toolbar>
              <div style={{paddingRight: '20px', display: open?"none":"inline-flex"}}><Logo /></div>
                {toolbarChildren}
                <div style={{
                    marginLeft: "auto",
                    marginRight: "0px",
                }}>
                  <Button variant='contained' color='warning' startIcon={<HelpIcon />} sx={{marginRight: '10px'}} onClick={() => {
                    window.open('https://share.hsforms.com/1y3Wy5mWBTT6PYS3vWoQ74wnw2dx', "_blank");
                  }}>
                  Support
                </Button>
                  {toolBarMenu}
                </div>
            </Toolbar>
        </AppBar>
        <Drawer
            variant="permanent"
            open={open}
        >
          <DrawerHeader>
            {open && <div style={{marginLeft:'0px', marginRight:'auto', display:'inline-flex',alignItems: 'center', flexWrap: 'wrap',}}>
                <div style={{display: 'inline-flex', paddingRight: '20px'}}>
                    <Logo />
                </div>
                <h2>VisitorAPI</h2>
            </div>}
          </DrawerHeader>
        <Divider />
            {drawerMenu}
        <Divider />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, overflow:'hidden'}}>
            <DrawerHeader />
            <Box width={1} style={{position:"fixed", zIndex: '1200'}}>
                <Paper square>
                    <Box p={2}>
                    <Breadcrumb links={breadcrumb} />
                    </Box>
                </Paper>
            </Box>
            <div style={{position: 'relative'}}>
            <Box mt={10} ml={3} mr={3} mb={3}>
              <BreadcrumbContext.Provider value={{setBreadcrumb}}>
                  {children}
              </BreadcrumbContext.Provider>
            </Box>
            </div>
        </Box>
    </Box>
  );
}

export default Layout;