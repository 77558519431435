import React from "react";

const Logo = ({size}) => {
    const fontSize = size || '48px';
    return (
        <>
            <img src="/64x64.png" alt="logo" width={fontSize} />
        </>
    )
}

export default Logo;