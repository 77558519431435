import React, { useContext, useEffect, useState, useRef } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { useHistory, Redirect } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { Paper, Box, Alert, Button, Stack} from "@mui/material";

const DeleteAccount = () => {
    const title = 'Delete Account';
    const history = useHistory();
    const { userData } = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [inSubmit, setInSubmit] = useState(false);
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const mountedRef = useRef(true);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/",
                text: userData.currentAccount.name,
                active: false
            },
            {
                to: "/account/"+userData.currentAccount.id+"/billing",
                text: 'Billing',
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    },[userData,setBreadcrumb,title]);

    useEffect(() => {
        return () => { 
            mountedRef.current = false
        }
    },[]);

    return (
        <>
            {success?(
                <Redirect to="/"></Redirect>
            ):(
                <>
                    <Paper>
                        <Box p={2}>
                            {error !== null && 
                                <Box p={3}>
                                    <Alert severity="error">{error}</Alert>
                                </Box>
                            }
                            <p>Your subscription is set to automatically renew at the end of this month.</p>
                            {userData.currentAccount.price > 0 && <p>Cancellation will trigger an immediate payment for the occured API usages in the current subscripotion period.</p>}
                            <p>Please be aware that once you proceed with the cancellation, it cannot be reversed. The cancellation will become effective shortly, and any subsequent API requests will result in 404 errors. Before proceeding, ensure you have removed or updated any relevant code on your websites to prevent unexpected issues.</p>
                            <p className="text-danger">Do you wish to proceed with cancelling your API subscription?</p>
                            <Stack direction="row" spacing={1} mt={2}>
                                <Button variant="contained" color="error" disabled={inSubmit} onClick={() => {
                                    setInSubmit(true);
                                    const cancelSubscription = CloudFunctions.httpsCallable('cancelSubscription');
                                    cancelSubscription({
                                        accountId: userData.currentAccount.id
                                    }).then(res => {
                                        if (!mountedRef.current) return null
                                        setSuccess(true);
                                        setInSubmit(false);
                                    }).catch(err => {
                                        if (!mountedRef.current) return null
                                        setError(err.message);
                                        setInSubmit(false);
                                    })
                                }}>
                                {inSubmit && 
                                    <Loader />
                                }
                                    Yes, I want to cancel the project</Button>
                                <Button variant="contained" color="secondary" onClick={() => history.push("/account/"+userData.currentAccount.id+"/billing")}>No, Go Back</Button>
                            </Stack>
                        </Box>
                    </Paper>
                </>
            )}
            
        </>
    )
}

export default DeleteAccount;